import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Profile() {
  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">プロフィール</h2>
      <hr className="my-4 md:my-8" />
      <div className="md:flex md:items-start">
        <div className="text-center">
          <StaticImage alt="gonsuna icon" src="../../static/chicken.png" className="w-32 mx-12 mb-4" />
          <div>ごんすな</div>
        </div>
        <div className="my-8 md:mt-0 md:flex-1">
          <div>
            日本のスタートアップで働くエンジニアです。画像処理系のAI技術、モバイルアプリ開発等を仕事にしています。乏しい技術力を磨くため日々勉強中です。
            <br /><br />
            趣味は、投資、バイク、映画、読書等です。個人開発で何か面白いことをやりたいと思っている人です。仲良くしてもらえると嬉しいです。
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Profile" />
